import React from 'react';
import Head from 'next/head';

interface Props { 
  title: string
  url: string
  description?: string
  image?: string
  ogImage?: string
  twitterImage?: string
  canonical?: string
}

const Meta : React.FC<Props> = props => {

  const {
    title,
    description,
    url,
    image, 
    ogImage,
    twitterImage,
    canonical,
  } = props;

  return (
    <Head>
      <title>{title}</title>
      <meta content={title} property="og:title" />
      <meta content={title} property="twitter:title" />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={`https://${process.env.NEXT_PUBLIC_HOST}${url}`} />
      <meta property="twitter:url" content={`https://${process.env.NEXT_PUBLIC_HOST}${url}`} />
      { description 
        ? <>
          <meta content={description} name="description" />
          <meta content={description} property="og:description" />
          <meta content={description} property="twitter:description" />  
        </>
        : <>
          <meta content="We built the world&#x27;s largest global remote recruiters community to generate a virtually unlimited talent pool for our customers. A data driven, predictive hiring process ensures we send your way only those App developers that you are most likely to hire, and will demonstrate high-performance, long-retention within the context of your own specific company." name="description" />
          <meta content="We built the world&#x27;s largest global remote recruiters community to generate a virtually unlimited talent pool for our customers. A data driven, predictive hiring process ensures we send your way only those App developers that you are most likely to hire, and will demonstrate high-performance, long-retention within the context of your own specific company." property="og:description" />
          <meta content="We built the world&#x27;s largest global remote recruiters community to generate a virtually unlimited talent pool for our customers. A data driven, predictive hiring process ensures we send your way only those App developers that you are most likely to hire, and will demonstrate high-performance, long-retention within the context of your own specific company." property="twitter:description" />
        </>
      }
      { image || ogImage 
        ? <meta property="og:image" content={ogImage || image} />
        : <meta property="og:image" content="/images/flatworld-cover-fb.png" />
      }
      {
        image || twitterImage
        ? <meta property="twitter:image" content={twitterImage || image} />
        : <meta property="twitter:image" content="/images/flatworld-cover-twitter.png" />
      }
      {
        canonical
        ? <link rel='canonical' href={`https://${process.env.NEXT_PUBLIC_HOST}${canonical}`} />
        : <link rel='canonical' href={`https://${process.env.NEXT_PUBLIC_HOST}${url}`} />
      }
    </Head>
  );
}

export default Meta;