import { IdentityContext } from "@/context/identity-context";
import { uriEncoded } from "@/lib/uri-encoded";
import { useRouter } from "next/router";
import { useContext, useState } from "react";

export default function useLinkedinOAuthRedirect() {
  const { togglJobOpening, togglId, token, roleUid } =
    useContext(IdentityContext);

  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const router = useRouter();

  async function startRedirect() {
    if ((!togglJobOpening || !togglId) && !roleUid && !token) {
      setErrorMessage(
        "We cannot find your application id. Please use the login link sent to your email"
      );
      return;
    }

    try {
      setErrorMessage("");
      setLoading(true);

      const path = getReqPath({
        togglJobOpening,
        togglId,
        roleUid,
        token,
        nextPath: router.query.next as string | undefined,
      });

      const res = await fetch(path);

      const { status } = res;

      if (status === 400) {
        setErrorMessage(
          "We cannot find your application id. Please use the login link sent to your email"
        );
        setLoading(false);
        return;
      }

      const { success, url } = await res.json();

      if (!success) {
        setErrorMessage("Something went wrong. Please try again later");
        setLoading(false);
        return;
      }

      window.location = url;
    } catch (e: any) {
      setErrorMessage("Something went wrong. Please try again later");
      setLoading(false);
    }
  }

  return {
    errorMessage,
    loading,
    startRedirect,
  };
}

interface GetPathParams {
  togglJobOpening: number | null;
  togglId: number | null;
  roleUid: string | null;
  token: string | null;
  nextPath?: string;
}

const getReqPath = (params: GetPathParams) => {
  const { togglId, togglJobOpening, token, nextPath, roleUid } = params;

  let path = "/api/redirect?";

  if (roleUid) path += uriEncoded`rid=${roleUid}`;
  if (togglId) path += uriEncoded`&toggl_id=${togglId}`;
  if (togglJobOpening)
    path += uriEncoded`&toggl_job_opening=${togglJobOpening}`;
  if (token) path += uriEncoded`&session=${token}`;
  if (nextPath) path += uriEncoded`&next=${nextPath}`;

  return path;
};
