import Meta from '@/components/commons/meta';
import { IdentityContext } from '@/context/identity-context';
import useLinkedinOAuthRedirect from '@/hooks/use-linkedin-oauth-redirect';
import { CandidatesPopup } from '@flatworldco/nextjs-components';
import { useContext } from 'react';

export default function Home() {
  const { ready } = useContext(IdentityContext);

  const { 
    startRedirect,
    loading,
    errorMessage,
  } = useLinkedinOAuthRedirect()

  return (
    <>
      <Meta
        title="Flatworld | Candidates | Login"
        description="Login to Flatworld Candidates Portal"
        url={`/`}
        image={`https://${process.env.NEXT_PUBLIC_HOST}/flatworld-cover.png`}
        ogImage={`https://${process.env.NEXT_PUBLIC_HOST}/flatworld-cover-fb.png`}
        twitterImage={`https://${process.env.NEXT_PUBLIC_HOST}/flatworld-cover-twitter.png`}
      />
      <CandidatesPopup
        heading="Verify your profile"
        disabled={!ready}
        button={{
          text: 'Sign in with LinkedIn',
          onClick: startRedirect,
        }}
        loading={loading}
        errorMessage={errorMessage}
      >
        <div className="
          leading-[20px] font-medium
          sm:text-[19px] sm:leading-[23px] sm:font-medium
        ">
          <p>
            <span className="font-bold">Sign in</span> to proceed with your application.
          </p>
          <p className="mt-5">
            <span className="font-bold text-brand-blue">Don&apos;t worry!</span><br/>
            We will not be able to post on your LinkedIn profile or read your data 😋
          </p>              
        </div>  
      </CandidatesPopup>
    </>
  )
}
